/* -------------------------------------------------------------------------- */
/*                                 TYPOGRAPHY                                 */
/* -------------------------------------------------------------------------- */

.multicolor-text {
	color: $c-blue-cloud;

	span {
		color: $c-white;
	}

	&--reverse {
		color: $c-white;

		span {
			color: $c-blue-cloud;
		}
	}
}
