/* -------------------------------------------------------------------------- */
/*                                  VARIABLES                                 */
/* -------------------------------------------------------------------------- */

/* --------------------------------- COLORS --------------------------------- */

$c-white: #ffffff;
$c-black: #000000;
$c-constant-black: #181a3a;

// BLUE
$c-blue-light: #519fde;
$c-blue-medium: #39499b;
$c-blue-dark: #2833b7;
$c-blue-ocean: #39499b;
$c-blue-river: #4c5fbd;
$c-blue-lagoon: #6a7ac8;
$c-blue-cloud: #97a2d8;
$c-blue-brand: #481af5;
$c-blue-brand-700: #8c97fa;

// PURPLE
$c-purple: #6f62d1;
$c-purple-portal: #7f73d6;
$c-purple-galaxy: #9482dd;
$c-purple-dream: #a790e3;
$c-purple-sunset: #c0a2e8;
$c-purple-rose: #e8dafc;
$c-purple-stark: #787d95;

// ORANGE
$c-orange: #f47532;

// GREY
$c-grey-snow: #f6f7fe;
$c-grey-steam: #fafafe;
$c-grey-mat: #d7d9ea;
$c-grey-rose: #b6aec7;
$c-grey-medium: #9393b3;
$c-grey-wet: #737390;
$c-grey-dark: #181a3a;

// STATUS
$c-status-success: #0cf380;
$c-status-warning: #fccd30;
$c-status-error: #f71c53;

/* ------------------------------- BREAKPOINTS ------------------------------ */

$breakpoints: (
	xl: 1512px,
	lg: 1200px,
	sm-lg: 1024px,
	md: 900px,
	sm: 768px,
	xs-lg: 540px,
	xs: 375px
);

/* ---------------------------------- FONTS --------------------------------- */

$fw-medium: 500;
$fw-semibold: 600;
$fw-bold: 700;
