/* -------------------------------------------------------------------------- */
/*                                    FONTS                                   */
/* -------------------------------------------------------------------------- */

@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600;700&display=swap");

/* ------------------------------- FONT-FAMILY ------------------------------ */

$montserrat: "Montserrat";
$telegraf: "Telegraf";

/* ----------------------------- TELEGRAF FONTS ----------------------------- */

@font-face {
	font-family: "Telegraf";
	src: url("../../fonts/Telegraf/Telegraf-UltraLight.woff2") format("woff");
	font-weight: 300;
}

@font-face {
	font-family: "Telegraf";
	src: url("../../fonts/Telegraf/Telegraf-Regular.woff2") format("woff");
	font-weight: 400;
}

@font-face {
	font-family: "Telegraf";
	src: url("../../fonts/Telegraf/Telegraf-Medium.ttf") format("truetype");
	font-weight: 500;
}

@font-face {
	font-family: "Telegraf";
	src: url("../../fonts/Telegraf/Telegraf-SemiBold.woff2") format("woff");
	font-weight: 600;
}

@font-face {
	font-family: "Telegraf";
	src: url("../../fonts/Telegraf/Telegraf-Bold.woff2") format("woff");
	font-weight: 700;
}
