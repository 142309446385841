/* --------------------------------- IMPORTS -------------------------------- */

@import "../../../assets/styles/config/fonts.scss";
@import "../../../assets/styles/config/variables.scss";
@import "../../../assets/styles/helpers/functions.scss";

/* --------------------------------- STYLES --------------------------------- */

.isLightBlue {
	color: $c-blue-brand-700;
}

.isDeepBlue {
	color: $c-blue-brand;
	text-decoration: none;
}

.presentationPanel {
	align-self: stretch;
	display: grid;
	grid-template-columns: 1fr;
	row-gap: 4.6rem;
	align-content: space-between;

	@media screen and (max-width: breakpoint("xs-lg")) {
		justify-items: center;
	}

	.presentationPanelContent {
		--productBlockRowsStructure: 3.5rem 2.7rem 3.5rem;

		@media screen and (max-width: breakpoint("sm-lg")) {
			--productBlockRowsStructure: 3.5rem 0.8rem 0 3.5rem;
		}

		max-width: 70rem;
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-template-rows: var(--productBlockRowsStructure) auto var(--productBlockRowsStructure);
		grid-template-areas:
			"blockLockpass ."
			"blockLockpass blockLocktransfer"
			". blockLocktransfer"
			"bodyText bodyText"
			". blockManagement"
			"blockLockfiles blockManagement"
			"blockLockfiles .";
		column-gap: 1.4rem;

		@media screen and (max-width: breakpoint("sm-lg")) {
			grid-template-columns: 2fr 1fr 2fr;
			grid-template-areas:
				"blockLockpass blockLockpass ."
				"blockLockpass blockLockpass ."
				". blockLocktransfer blockLocktransfer"
				". blockLocktransfer blockLocktransfer"
				"bodyText bodyText bodyText"
				". blockManagement blockManagement"
				". blockManagement blockManagement"
				"blockLockfiles blockLockfiles ."
				"blockLockfiles blockLockfiles .";
		}

		@media screen and (max-width: breakpoint("sm")) {
			grid-template-columns: [bodyText-start] minmax(0, 1fr) [bodyText-end];
			grid-template-rows: [bodyText-start] minmax(0, 1fr) [bodyText-end];
			grid-template-areas: unset;
		}

		.productBlock {
			display: grid;
			grid-template-columns: 6.4rem minmax(0, 17rem);
			grid-template-rows: 6.4rem;
			grid-template-areas: "iconProduct textProduct";
			column-gap: 2rem;
			align-items: center;

			@media screen and (max-width: breakpoint("sm-lg")) {
				grid-template-columns: 4rem minmax(0, 17rem);
				grid-template-rows: 4rem;
			}

			@media screen and (max-width: breakpoint("sm")) {
				display: none;
			}

			&.isReversed {
				grid-template-columns: minmax(0, 17rem) 6.4rem;
				grid-template-areas: "textProduct iconProduct";

				@media screen and (max-width: breakpoint("sm-lg")) {
					grid-template-columns: minmax(0, 17rem) 4rem;
				}

				.iconProduct {
					@media screen and (max-width: breakpoint("sm-lg")) {
						transform: translate(-2.4rem, -1.3rem);
					}
				}
			}

			&.isLockpass {
				grid-area: blockLockpass;
			}

			&.isLocktransfer {
				grid-area: blockLocktransfer;
			}

			&.isLockfiles {
				grid-area: blockLockfiles;
			}

			&.isManagement {
				grid-area: blockManagement;
			}

			.iconProduct {
				grid-area: iconProduct;
				align-self: start;
				transform: translate(-2rem, -1.7rem);

				@media screen and (max-width: breakpoint("sm-lg")) {
					width: 8rem;
					height: auto;
					transform: translate(-1.1rem, -1.3rem);
				}
			}

			.textProduct {
				grid-area: textProduct;
				font-size: 1.6rem;
				line-height: 1.9rem;

				@media screen and (max-width: breakpoint("sm-lg")) {
					font-size: 1.2rem;
					line-height: 1.3rem;
				}

				.isMedium {
					font-weight: 500;
				}
			}
		}

		.mainText {
			max-width: 52.7rem;
			grid-area: bodyText;
			padding-block: 2.1rem 1.3rem;
			color: $c-black;
			font-size: 4rem;
			line-height: 4.8rem;

			@media screen and (max-width: breakpoint("sm-lg")) {
				max-width: 32.3rem;
				font-size: 3.3rem;
				line-height: 4rem;
				padding-block: 1.3rem 2rem;
			}

			@media screen and (max-width: breakpoint("sm")) {
				max-width: 100%;
				padding-block: 0;
				text-align: center;
			}
		}
	}

	.presentationPanelFooter {
		display: grid;
		row-gap: 1.2rem;

		@media screen and (max-width: breakpoint("sm-lg")) {
			row-gap: 1.7rem;
		}

		@media screen and (max-width: breakpoint("sm")) {
			grid-template-columns: minmax(0, 40rem);
			justify-content: center;
		}

		@media screen and (max-width: breakpoint("xs-lg")) {
			max-width: min(100%, 31.7rem);
		}

		.text {
			justify-self: start;
			font-size: 1.6rem;
			line-height: 1.7rem;

			@media screen and (max-width: breakpoint("sm-lg")) {
				font-size: 1.4rem;
				line-height: 1.5rem;
			}

			@media screen and (max-width: breakpoint("sm")) {
				justify-self: center;
			}
		}

		.clientsList {
			padding-inline: 0.4rem;
			display: grid;
			align-items: center;
			grid-template-columns: repeat(5, auto);
			align-items: center;
			justify-content: space-between;

			@media screen and (min-width: ((breakpoint("sm")) + 1)) and (max-width: breakpoint("sm-lg")) {
				max-width: 29.7rem;
			}

			.clientsListItem {
				height: 4.5rem;

				@media screen and (max-width: breakpoint("sm-lg")) {
					height: 3.64rem;
				}

				.iconClient {
					@media screen and (max-width: breakpoint("sm-lg")) {
						width: auto;
						height: 100%;
					}
				}
			}
		}
	}
}

.formPanel {
	display: grid;
	grid-template-rows: 1fr;
	padding: 2.9rem 3.3rem;
	min-height: min(100%, 61.5rem);
	border-radius: 0.8rem;
	background-color: $c-white;
	box-shadow: 0 0.1rem 0.7rem 0 rgba($c-blue-brand, 0.05);

	@media screen and (max-width: breakpoint("sm-lg")) {
		padding: 2.5rem 2.3rem;
	}

	&.hasFormRequestError {
		grid-template-rows: auto 1fr auto;
	}

	.formTextError {
		font-size: 1.6rem;
		line-height: 1.8rem;
		color: $c-status-error;
	}

	.formContent {
		display: grid;
		grid-template-columns: 1fr;
		grid-template-rows: 1fr auto;
		align-content: space-between;
		row-gap: 2.4rem;

		@media screen and (max-width: breakpoint("xs-lg")) {
			row-gap: 5rem;
		}

		.fieldsList {
			display: grid;
			grid-template-columns: repeat(2, 1fr);
			align-content: start;
			align-items: end;
			gap: 3rem 2.8rem;

			@media screen and (max-width: breakpoint("sm-lg")) {
				row-gap: 2rem;
			}

			@media screen and (max-width: breakpoint("xs-lg")) {
				grid-template-columns: 1fr;
			}
		}
	}
}

.smallHiddenText {
	font-size: 2rem;
	line-height: 2.6rem;
	letter-spacing: 0.02em;
	text-align: center;

	@media screen and (min-width: (breakpoint("sm") + 1)) {
		display: none;
	}
}

.formButton {
	justify-self: var(--formButton-position, center);
	width: min(100%, 35.9rem);
	display: grid;
	align-items: center;
	grid-template-columns: repeat(2, auto);
	column-gap: 1.5rem;
	justify-content: center;
	padding: 2rem;
	border-radius: 3rem;
	border: none;
	background-color: $c-blue-brand;
	color: $c-white;
	letter-spacing: 0.08em;
	text-transform: uppercase;
	font-size: 1.4rem;
	line-height: 1.5rem;
	font-weight: 600;
	cursor: pointer;

	&:hover {
		.icon {
			translate: 1rem 0;
		}
	}

	.icon {
		transition-duration: 0.3s;
	}
}

.fieldItem {
	position: relative;
	z-index: 100;
	display: grid;
	grid-template-columns: minmax(0, 1fr);
	row-gap: 0.8rem;

	&.isSelect {
		z-index: 200;

		&:has(+ .fieldItem.isSelect) {
			@media screen and (max-width: breakpoint("xs-lg")) {
				z-index: 300;
			}
		}
	}

	&.isPhoneNumber {
		z-index: 150;
	}

	&.isInvalid {
		--input-border-color: #{$c-status-error};
	}

	&.isExpanded {
		grid-column: 1 / span 2;

		@media screen and (max-width: breakpoint("xs-lg")) {
			grid-column: 1 / -1;
		}
	}

	&.hasMoreHeadSpaceOnMobile {
		@media screen and (max-width: breakpoint("xs-lg")) {
			margin-top: 3rem;
		}
	}

	.checkbox {
		display: grid;
		grid-template-columns: 1.75rem auto;
		align-items: center;
		justify-content: start;
		column-gap: 1.2rem;

		.inputCheckboxContainer {
			position: relative;
			width: 1.75rem;
			height: 1.75rem;
			background-color: $c-white;
			border: solid 0.14rem $c-grey-mat;
			border-radius: 0.35rem;
			cursor: pointer;
			transition-duration: 0.3s;

			&:has(.inputCheckboxControl:checked) {
				background-color: $c-blue-brand;
				border-color: $c-blue-brand;
			}

			.inputCheckboxControl {
				position: absolute;
				opacity: 0;
				top: -1.4rem;
				right: -1.4rem;
				bottom: -1.4rem;
				left: -1.4rem;
				margin: 0;
				border: 0;
				cursor: pointer;
			}
		}

		.inputCheckboxLabel {
			.checkboxLabel {
				color: $c-constant-black;
				font-size: 1.6rem;
				line-height: 1.7rem;
			}
		}
	}

	.fieldLabel {
		justify-self: start;
		color: $c-constant-black;
		font-size: 1.6rem;
		font-weight: 500;
		line-height: 1.7rem;

		&::after {
			content: " *";
			color: $c-status-error;
			font-weight: 700;
		}
	}

	.invalidFieldText {
		color: $c-status-error;
		font-size: 1.3rem;
		line-height: 1.4rem;
		font-weight: 300;
	}

	.phoneNumberFieldInputContainer {
		.special-label {
			display: none;
		}
	}

	.fieldInput {
		padding: 1.2rem 2rem;
		border: solid 0.15rem var(--input-border-color, $c-grey-mat);
		border-radius: 0.4rem;
		background-color: $c-grey-steam;
		color: $c-constant-black;
		font-size: 1.6rem;
		line-height: 1.7rem;
		transition-duration: 0.3s;

		&::placeholder {
			color: $c-grey-medium;
		}

		&:placeholder-shown {
			text-overflow: ellipsis;
		}

		&:focus {
			border-color: var(--input-border-color, $c-blue-brand);
		}

		&.isPhoneNumberFieldInput {
			padding-left: 6.2rem;
			width: 100%;
		}
	}
}

.select {
	position: relative;

	.toggleListButton {
		position: absolute;
		z-index: 200;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		cursor: pointer;
		border: none;
		background-color: transparent;
	}

	.selectValue {
		position: relative;
		z-index: 100;
		display: grid;
		grid-template-columns: minmax(0, 1fr) auto;
		align-items: center;
		justify-content: space-between;
		column-gap: 0.6rem;

		.selectValueText {
			white-space: nowrap;
			overflow: hidden;
		}

		&.hasPlaceholder {
			.selectValueText {
				color: $c-grey-medium;
			}
		}

		.icon {
			width: 1.22rem;
			height: auto;

			path {
				fill: $c-grey-medium;
			}
		}
	}

	.selectOptionsListContainer {
		position: absolute;
		opacity: 0;
		visibility: hidden;
		left: 0;
		bottom: -1.5rem;
		width: 100%;
		translate: 0 calc(100% + 0.4rem);
		padding: 0.4rem 0;
		overflow: scroll;
		max-height: 22.8rem;
		border-radius: 0.4rem;
		box-shadow: 0 0.1rem 0.7rem 0 rgba($c-blue-brand, 0.15);
		background-color: $c-white;
		transition-duration: 0.3s;

		&.isVisible {
			opacity: 1;
			visibility: visible;
			bottom: 0;
		}

		.selectOptionsList {
			display: grid;
			grid-template-columns: 1fr;

			.selectOptionItem {
				display: grid;
				grid-template-columns: auto;

				.button {
					// overflow: hidden;
					padding-block: 1.2rem;
					padding-inline: 1.8rem;
					border: none;
					background-color: transparent;
					text-align: left;
					font-size: 1.6rem;
					line-height: 1.7rem;
					cursor: pointer;
					white-space: nowrap;
					transition-duration: 0.3s;

					&:hover,
					&.isSelected {
						background-color: rgba($c-blue-brand, 0.1);
					}
				}
			}
		}
	}
}

.hiddenOnMobile {
	@media screen and (max-width: breakpoint("sm")) {
		display: none;
	}
}
