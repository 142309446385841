/* -------------------------------------------------------------------------- */
/*                                    STYLE                                   */
/* -------------------------------------------------------------------------- */

/* --------------------------------- IMPORTS -------------------------------- */
@import './config/fonts.scss';
@import './config/variables.scss';
@import './config/reset.scss';
@import './helpers/functions.scss';
@import './helpers/typography.scss';

.app {
    min-height: 100vh;
    max-width: 100%;
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    background: linear-gradient(270deg, $c-white 0%, $c-white 40.19%, #ECECF1 100%, $c-white 100%);

	@media screen and (max-width: breakpoint(sm)) {
		background: linear-gradient(162.67deg, #F1F1FB 10.56%, $c-white 86.44%);
	}
}
