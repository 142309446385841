/* -------------------------------------------------------------------------- */
/*                                    RESET                                   */
/* -------------------------------------------------------------------------- */

html,
body {
	padding: 0;
	margin: 0;
}

html {
	font-size: 62.5%;
}

body {
	font-family: $montserrat;
	color: $c-grey-dark;
}

* {
	box-sizing: border-box;
}

input,
button {
	font-family: $montserrat;
	outline: none;
}

a {
	text-decoration: none;
}

p {
	margin: 0;
}

img {
	display: block;
}

ol,
ul {
	list-style-type: none;
	padding: 0;
	margin: 0;
}
