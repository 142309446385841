@import '../../assets/styles/config/variables.scss';

// LOADER
.loader {
	position: fixed;
	left: 0;
	top: 0;
	height: 100%;
	width: 100%;
	z-index: 10000;
	animation: 0.4s ease-in-out appear;
	animation-fill-mode: backwards;
	background-color: rgba($c-grey-snow, 0.8);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

	.loader-container {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		flex: 2;
	}
}

.loader-wrapper {
	position: relative;
	width: 150px;
	height: 150px;
	z-index: 1010;
}

#loader {
	display: block;
	position: relative;
	left: 50%;
	top: 50%;
	width: 150px;
	height: 150px;
	margin: -75px 0 0 -75px;
	border-radius: 50%;
	border: 3px solid transparent;
	border-top-color: $c-grey-wet;
	-webkit-animation: spin 1.7s linear infinite;
	animation: spin 1.7s linear infinite;
	z-index: 1010;
}

#loader:before {
	content: "";
	position: absolute;
	top: 5px;
	left: 5px;
	right: 5px;
	bottom: 5px;
	border-radius: 50%;
	border: 3px solid transparent;
	border-top-color: $c-orange;
	-webkit-animation: spin-reverse 0.6s linear infinite;
	animation: spin-reverse 0.6s linear infinite;
}

.loader-wrapper .loader-section.section-left {
	left: 0;
}

.loader-wrapper .loader-section.section-right {
	right: 0;
}

.loaded .loader-wrapper .loader-section.section-left {
	-webkit-transform: translateX(-100%);
	transform: translateX(-100%);
	transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.loaded .loader-wrapper .loader-section.section-right {
	-webkit-transform: translateX(100%);
	transform: translateX(100%);
	transition: all 0.7s 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}

.loaded #loader {
	opacity: 0;
	transition: all 0.3s ease-out;
}

.loaded .loader-wrapper {
	visibility: hidden;
	-webkit-transform: translateY(-100%);
	transform: translateY(-100%);
	transition: all 0.3s 1s ease-out;
}

// MINILOADER
#loader-1 {
	display: flex;

	> span {
		height: 0.5rem;
		width: 0.5rem;
		margin-right: 2px;
		background: $c-grey-wet;
		border-radius: 0px;
	}
}

#loader-1 span:nth-child(1) {
	border-radius: 100%;
	-webkit-animation: scale 1.7s 0.1s infinite
		cubic-bezier(0.6, -0.28, 0.735, 0.045);
	animation: scale 1.7s 0.1s infinite cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

#loader-1 span:nth-child(2) {
	border-radius: 100%;
	-webkit-animation: scale 1.7s 0.2s infinite
		cubic-bezier(0.6, -0.28, 0.735, 0.045);
	animation: scale 1.7s 0.2s infinite cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

#loader-1 span:nth-child(3) {
	border-radius: 100%;
	-webkit-animation: scale 1.7s 0.3s infinite
		cubic-bezier(0.6, -0.28, 0.735, 0.045);
	animation: scale 1.7s 0.3s infinite cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

#loader-1 span:nth-child(4) {
	border-radius: 100%;
	-webkit-animation: scale 1.7s 0.4s infinite
		cubic-bezier(0.6, -0.28, 0.735, 0.045);
	animation: scale 1.7s 0.4s infinite cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

#loader-1 span:nth-child(5) {
	border-radius: 100%;
	-webkit-animation: scale 1.7s 0.5s infinite
		cubic-bezier(0.6, -0.28, 0.735, 0.045);
	animation: scale 1.7s 0.5s infinite cubic-bezier(0.6, -0.28, 0.735, 0.045);
}

/* -------------------------------- KEYFRAMES ------------------------------- */

@keyframes scale {
	0% {
		transform: scale(0);
	}
	25% {
		transform: scale(0.9, 0.9);
		background: $c-grey-wet;
	}
	50% {
		transform: scale(1, 1);
		margin: 0 0.3rem;
		background: $c-grey-wet;
	}
	100% {
		transform: scale(0);
	}
}

@keyframes appear {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
		transform: rotate(360deg);
	}
}

@-webkit-keyframes spin-reverse {
	0% {
		-webkit-transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(-360deg);
	}
}

@keyframes spin-reverse {
	0% {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg);
	}

	100% {
		-webkit-transform: rotate(-360deg);
		transform: rotate(-360deg);
	}
}
