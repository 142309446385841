/* --------------------------------- IMPORTS -------------------------------- */

@import "./../../assets/styles/config/fonts.scss";
@import "./../../assets/styles/config/variables.scss";
@import "./../../assets/styles/helpers/functions.scss";

/* --------------------------------- STYLES --------------------------------- */

.monoStepFunnelLayout {
	width: 100%;
	position: relative;
	padding: 3.5rem 2.2rem;
	margin-inline: auto;
	font-family: $telegraf;
	display: grid;
	grid-template-columns: min(100%, 160rem);
	grid-template-rows: auto 1fr;
	justify-content: center;

	@media screen and (max-width: breakpoint("sm-lg")) {
		padding-block: 2.5rem;
	}

	@media screen and (max-width: breakpoint("sm")) {
		row-gap: 5.6rem;
		padding-block: 2.7rem;
	}
}

.monoStepFunnel {
	background-image: url("../../assets/images/decorations/monoStepFunnelBackground.png");
	background-size: 100% auto;
	background-position: center top;

	@media screen and (max-width: breakpoint(xl)) {
		grid-template-columns: min(100%, 130rem);
	}

	&.monoStepFunnelLayout {
		@media screen and (min-width: (breakpoint("sm") + 1)) {
			row-gap: 3rem;
		}
	} 

	input,
	button {
		font-family: $telegraf;
	}

	.logo {
		width: 15.6rem;
		height: auto;
		justify-self: center;

		@media screen and (max-width: breakpoint("sm-lg")) {
			width: 14.5rem;
		}

		@media screen and (max-width: breakpoint("sm")) {
			justify-self: start;
		}
	}

	.contentWrapper {
		display: grid;
		grid-template-columns: 1fr;
		align-items: start;
	}

	.content {
		height: 100%;
		max-height: 63rem;
		margin-block: auto;
		display: grid;
		grid-template-columns: minmax(0, 63.7rem) minmax(0, 52.7rem);
		align-items: start;
		justify-content: space-between;
		gap: 1.8rem;

		@media screen and (max-width: breakpoint("sm-lg")) {
			max-height: 52.6rem;
			grid-template-columns: 1fr minmax(0, 47.2rem);
		}

		@media screen and (max-width: breakpoint("sm")) {
			max-height: unset;
			grid-template-columns: minmax(0, 1fr);
			row-gap: 6rem;
		}

		@media screen and (max-width: breakpoint("xs-lg")) {
			row-gap: 7.4rem;
		}
	}
}

.monoStepFunnelSuccess {
	display: grid;
	align-items: center;
	background-color: $c-white;
	background-image: linear-gradient(90deg, $c-white 50%, rgba($c-white, 0) 50%, rgba($c-white, 0) 100%),
		url("../../assets/images/decorations/monoStepFunnelSuccessBackground.png");
	background-size: 100%;
	background-position: left top;
	background-repeat: no-repeat;
	row-gap: 6.5rem;

	@media screen and (max-width: breakpoint("sm-lg")) {
		background-image: linear-gradient(90deg, $c-white 54%, rgba($c-white, 0) 54%, rgba($c-white, 0) 100%),
			url("../../assets/images/decorations/monoStepFunnelSuccessBackground.png");
			row-gap: 3rem;
	}

	@media screen and (min-width: (breakpoint("sm") + 1)) {
		overflow: clip;
		max-height: 100vh;
	}

	@media screen and (max-width: breakpoint("sm")) {
		background-image: none;
		background-color: $c-white;
		row-gap: 7.2rem;
		align-items: start;
	}

	&.monoStepFunnelLayout {
		@media screen and (max-width: breakpoint("sm-lg")) {
			padding-block: 4.5rem;
			grid-template-columns: min(100%, 94rem);
			justify-content: end;
		}

		@media screen and (max-width: breakpoint("sm")) {
			padding-top: 3.5rem;
			padding-block: 2.7rem;
		}
	}

	.iconLink {
		@media screen and (max-width: breakpoint("sm")) {
			justify-self: center;
		}
	}

	.content {
		display: grid;
		grid-template-columns: calc(50% - 7rem) 7rem 50%;
		grid-template-rows: max(3.5rem, 5vh) auto auto;
		grid-template-areas:
			"textContent . ."
			"textContent imageContent imageContent"
			". imageContent imageContent";

		@media screen and (max-width: breakpoint("sm-lg")) {
			grid-template-columns: [textContent-start] 50% [imageContent-start] 2% [textContent-end] 48% [imageContent-end];
			grid-template-rows: [textContent-start] max(3.5rem, 5vh) [imageContent-start] auto [textContent-end imageContent-end];
			grid-template-areas: unset;
		}

		@media screen and (max-width: breakpoint("sm")) {
			grid-template-columns: [textContent-start] 1fr [textContent-end];
			grid-template-rows: [textContent-start] auto [textContent-end];
		}

		.textContent {
			grid-area: textContent;
			padding-right: 3rem;

			@media screen and (max-width: breakpoint("sm-lg")) {
				--formButton-position: start;

				display: grid;
				grid-template-columns: minmax(0, 50rem);
				justify-content: center;
				padding-right: 1.8rem;
			}

			@media screen and (max-width: breakpoint("sm")) {
				row-gap: 6rem;
				padding-right: 0;
			}

			.title {
				margin-bottom: 2rem;
				font-size: 4rem;
				line-height: 4.8rem;
				font-weight: 500;

				@media screen and (max-width: breakpoint("sm-lg")) {
					max-width: 48rem;
					font-size: 3.7rem;
					line-height: 4.44rem;
				}

				@media screen and (max-width: breakpoint("sm")) {
					text-align: center;
					margin-bottom: 0;
					font-size: 3.3rem;
					line-height: 4rem;
				}
			}

			.text {
				width: min(100%, 50rem);
				margin-block: auto;
				justify-self: center;
				margin-bottom: 5rem;
				font-size: 2rem;
				line-height: 2.6rem;
				font-weight: 300;
				letter-spacing: 0.02em;

				@media screen and (max-width: breakpoint("sm-lg")) {
					width: min(100%, 42.3rem);
					font-size: 1.8rem;
					line-height: 2.34rem;
				}

				@media screen and (max-width: breakpoint("sm")) {
					width: min(100%, 56rem);
					text-align: center;
					margin-block: 0;
					font-size: 2rem;
					line-height: 2.4rem;
					letter-spacing: 0.02em;
					width: min(100%, 34.6rem);
				}

				.isMedium {
					font-weight: 500;
				}
			}
		}

		.imageContent {
			grid-area: imageContent;
			display: grid;
			grid-template-columns: [imageLockpassPasswordPolicy-start] 15.3rem [imageLockpassApp-start] 1fr [imageLockpassPasswordPolicy-end] 16.7rem [imageLockpassApp-end];
			grid-template-rows: [imageLockpassApp-start] 37.4rem [imageLockpassPasswordPolicy-start] 3rem [imageLockpassApp-end] auto [imageLockpassPasswordPolicy-end];

			@media screen and (max-width: breakpoint("sm-lg")) {
				grid-template-columns: [imageLockpassPasswordPolicy-start] 4.3rem [imageLockpassApp-start] 1fr [imageLockpassPasswordPolicy-end] 9.7rem [imageLockpassApp-end];
				grid-template-rows: [imageLockpassApp-start] 18.4rem [imageLockpassPasswordPolicy-start] 5rem [imageLockpassApp-end] 10rem [imageLockpassPasswordPolicy-end];
			}

			@media screen and (max-width: breakpoint("xl")) {
				grid-template-rows: [imageLockpassApp-start] 29.4rem [imageLockpassPasswordPolicy-start] 3rem [imageLockpassApp-end] auto [imageLockpassPasswordPolicy-end];
			}

			@media screen and (max-width: breakpoint("lg")) {
				grid-template-rows: [imageLockpassApp-start] 25.4rem [imageLockpassPasswordPolicy-start] 3rem [imageLockpassApp-end] auto [imageLockpassPasswordPolicy-end];
			}

			@media screen and (max-width: breakpoint("sm")) {
				display: none;
			}

			.imageLockpassAppContainer {
				grid-area: imageLockpassApp;
				position: relative;

				.image {
					@media screen and (max-width: breakpoint("sm-lg")) {
						width: calc(100% + 5rem);
					}
				}

				.imageLogoAnssi {
					position: absolute;
					z-index: 100;
					right: 9rem;
					bottom: -4rem;
					height: 14.8rem;
					width: auto;

					@media screen and (max-width: breakpoint("sm-lg")) {
						height: 9.4rem;
						right: -0.5rem;
						bottom: 0rem;
					}
				}
			}

			.imageLockpassPasswordPolicyContainer {
				grid-area: imageLockpassPasswordPolicy;
				translate: -2rem 0;
			}

			.image {
				width: 100%;
				height: auto;
			}
		}
	}
}
