/* --------------------------------- IMPORTS -------------------------------- */

@import '../../assets/styles/config/variables.scss';
@import '../../assets/styles/helpers/functions.scss';

%reset-btn {
    border: none;
    background: transparent;
    background-color: transparent;
    padding: 0;
    margin: 0;
    text-decoration: none;
    color: $c-black;
}

.btn {
    @extend %reset-btn;
	cursor: pointer;
}

.btn-filled {
	padding: 1.6rem 2.1rem;
	display: grid;
	text-align: center;
	align-items: center;
	justify-items: center;
	background-color: $c-blue-dark;
	color: $c-white;
	font-size: 2rem;
	font-weight: $fw-semibold;
	line-height: 2.4rem;
	border: solid 0.2rem $c-grey-snow;
	border-radius: 0.3rem;
	transition-duration: 0.3s;

	@media screen and (max-width: breakpoint(sm)) {
		font-size: 1.6rem;
		line-height: 2rem;
		padding: 1.2rem 1.8rem;
	}

	&:not(.is-disabled) {
		border-color: $c-blue-dark;

		&:hover {
			background-color: transparent;
			color: $c-blue-dark;
		}
	}

	&.is-disabled {
		color: $c-grey-wet;
		background-color: $c-grey-snow;

		@media screen and (max-width: breakpoint(sm)) {
			border-color: $c-grey-mat;
		}
	}
}

.btn-arrow {
    display: grid;
    grid-template-columns: 0.6rem auto;
    column-gap: 0.85rem;
    align-items: center;
    color: $c-grey-medium;
    font-size: 1.5rem;
    font-weight: $fw-semibold;
    line-height: 1.8rem;

	&:hover {
		padding-left: 1rem;

		&::before {
			transform: translateX(-1rem);
		}
	}

    &::before {
        content: '';
		width: 0.6rem;
        height: 0.9rem;
        background-image: url('../../assets/icons/icon-chevron-left.svg');
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
		transition-duration: 0.3s;
    }
}
