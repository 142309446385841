/* --------------------------------- IMPORTS -------------------------------- */

@import "./../assets/styles/config/fonts.scss";
@import "./../assets/styles/config/variables.scss";
@import "./../assets/styles/helpers/functions.scss";

/* --------------------------------- LAYOUT --------------------------------- */
.layout {
	display: grid;
	grid-template-columns: 1fr minmax(0, 50rem);
	column-gap: 5rem;
	padding: 5rem 6rem 4.5rem;

	@media screen and (max-width: breakpoint(lg)) {
		grid-template-columns: 1fr minmax(0, 41.1rem);
		column-gap: 4.5rem;
		padding: 3.2rem 3.8rem 3.2rem 5rem;
	}

	@media screen and (max-width: breakpoint(md)) {
		grid-template-columns: 1fr minmax(0, 31.2rem);
		column-gap: 3.8rem;
		padding: 3.2rem 2rem 3.2rem 5rem;
	}

	@media screen and (max-width: breakpoint(sm)) {
		grid-template-columns: 1fr;
		padding: 5rem 2rem 3.2rem;
	}

	&--calendar {
		@media screen and (min-width: breakpoint(sm) + 1) {
			grid-template-columns: 1fr 10.7rem;
		}
	}

	&--success-message {
		grid-template-columns: 1fr;
	}
}

/* ---------------------------------- FORM ---------------------------------- */
.form {
	width: min(57.4rem, 100%);

	.form-row {
		display: grid;
		grid-template-columns: repeat(auto-fit, minmax(25rem, 1fr));
		gap: 2.8rem 3.4rem;
	}

	.form-error {
		margin: 1rem 0 0 0.4rem;
		color: $c-status-error;
		font-size: 1.5rem;
		font-weight: $fw-semibold;
		line-height: 1.8rem;
	}
}

/* --------------------------------- INPUTS --------------------------------- */
.form-input-wrapper {
	display: grid;
	grid-template-columns: minmax(0, 1fr);
	gap: 1.6rem;

	&--checkbox {
		position: relative;
		display: grid;
		grid-template-columns: 2.1rem auto;
		column-gap: 1.5rem;
		align-items: center;
		justify-content: start;

		label {
			color: $c-grey-wet;
			font-size: 1.7rem;
			font-weight: $fw-semibold;
			line-height: 2.1rem;
			cursor: pointer;

			@media screen and (max-width: breakpoint(sm)) {
				font-size: 1.5rem;
				line-height: 1.8rem;
			}

			&::before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				width: 2.1rem;
				height: 2.1rem;
				border: solid 0.2rem $c-blue-cloud;
				border-radius: 0.3rem;
				background-color: $c-white;
				box-sizing: border-box;
			}

			&::after {
				content: "";
				position: absolute;
				top: 0.4rem;
				left: 0.4rem;
				width: 1.4rem;
				height: 1.4rem;
				background-image: url("../assets/icons/icon-check-green.svg");
				background-repeat: no-repeat;
				background-size: contain;
				background-position: center;
				opacity: 0;
				// transform: rotateZ(45deg);
				transform: translateY(-0.25rem);
				transition-duration: 0.3s;
			}
		}

		input {
			margin: 0;
			padding: 0;
			width: 100%;
			opacity: 0;
			height: 2.1rem;

			&:checked + label::after {
				opacity: 1;
				transform: translateY(0);
			}
		}

		a {
			color: $c-orange;
			cursor: pointer;
		}
	}

	&:not(.form-input-wrapper--checkbox) {
		label,
		.special-label {
			color: $c-grey-dark;
			font-size: 2.1rem;
			font-weight: $fw-semibold;
			line-height: 2.6rem;

			@media screen and (max-width: breakpoint(sm)) {
				font-size: 1.7rem;
				line-height: 2.1rem;
			}
		}

		.input {
			margin: 0;
			padding: 2.2rem 3.8rem 1.8rem 1.8rem;
			border: solid 0.2rem $c-grey-mat;
			border-radius: 0.3rem;
			font-size: 1.7rem;
			font-weight: $fw-semibold;
			line-height: 2.1rem;
			transition-duration: 0.3s;

			@media screen and (max-width: breakpoint(sm)) {
				padding: 1.7rem 3.8rem 1.3rem 1.8rem;
				font-size: 1.4rem;
				line-height: 1.8rem;
			}

			&:focus {
				border-color: $c-orange;
				// remove box shoadow from input tel (react-phone-input-2)
				box-shadow: none;
			}

			&--error,
			&--error:focus {
				border-color: $c-status-error;
			}
		}
	}
}

/* ---------------------------- LANGUAGES SELECT ---------------------------- */
.language-select {
	position: absolute;
	z-index: 20;
	bottom: 0.3rem;
	left: -1.8rem;
	display: grid;
	grid-template-areas:
		"en"
		"fr"
		"active";
	justify-self: start;
	min-width: 11.8rem;
	border: solid 0.2rem transparent;
	border-radius: 0.6rem;
	overflow: hidden;
	transition-duration: 0.3s;

	@media screen and (max-width: breakpoint(lg)) {
		bottom: -0.2rem;
	}

	@media screen and (max-width: breakpoint(sm)) {
		bottom: -1.2rem;
	}

	&:not(.is-open) {
		.link:not(.is-active) {
			opacity: 0;
			visibility: hidden;
		}
	}

	&.is-open {
		background-color: rgba($c-white, 0.5);
		border-color: $c-grey-dark;

		.link {
			&:not(.is-active) {
				&:last-child {
					margin-bottom: 0;
				}
			}

			&.is-active {
				&::after {
					transform: rotateZ(180deg);
				}
			}
		}
	}

	&.isReversed {
		z-index: 1000;
		top: 3.5rem;
		right: 9.2rem;
		bottom: unset;
		left: unset;
		grid-template-areas:
			"active"
			"fr"
			"en";

		@media screen and (max-width: breakpoint("sm-lg")) {
			top: 2.5rem;
			right: 3rem;
		}

		@media screen and (max-width: breakpoint("sm")) {
			top: 1.6rem;
			right: 2rem;
		}
	}

	.link {
		padding: 1rem 1.6rem;
		color: $c-grey-dark;
		font-size: 1.5rem;
		font-weight: $fw-medium;
		line-height: 1.8rem;
		text-align: left;
		transition-duration: 0.3s;

		&:not(.is-active) {
			color: $c-white;
			background-color: $c-grey-dark;

			&[data-lg="fr-FR"] {
				grid-area: fr;
			}

			&[data-lg="en-US"] {
				grid-area: en;
			}

			&:last-child {
				margin-bottom: -1rem;
			}
		}

		&.is-active {
			display: grid;
			grid-auto-flow: column;
			align-items: center;
			column-gap: 1rem;
			justify-content: space-between;
			grid-area: active;

			&::after {
				content: "";
				width: 0.9rem;
				height: 0.9rem;
				background-image: url("../assets/icons/icon-chevron-down.svg");
				background-repeat: no-repeat;
				background-size: contain;
				background-position: center;
				transform: rotateZ(0);
				transform-origin: center;
				transition-duration: 0.3s;
			}
		}
	}
}

/* ------------------------------ BOARD & SLIDE ----------------------------- */
.board-wrapper {
	position: relative;
	z-index: 20;
	display: grid;
	grid-template-rows: auto 1fr;
	padding-bottom: 1.5rem;

	@media screen and (max-width: breakpoint(lg)) {
		padding-bottom: 1rem;
	}

	@media screen and (max-width: breakpoint(sm)) {
		padding-bottom: 0;
	}

	.logo {
		width: 17rem;
		height: auto;

		@media screen and (max-width: breakpoint(sm)) {
			width: 12.5rem;
		}
	}

	.pagination {
		display: grid;
		row-gap: 0.7rem;
		justify-items: end;
		width: 100%;

		@media screen and (min-width: breakpoint(sm) + 1) {
			// negative value of top is equal to logo height
			position: absolute;
			top: -2.9rem;
			right: 0;
			width: min(46rem, calc(100% - 20rem));
		}

		@media screen and (max-width: breakpoint(sm)) {
			margin-bottom: 3rem;
		}

		.text {
			color: $c-grey-wet;
			font-size: 1.3rem;
			font-weight: $fw-medium;
			line-height: 1.6rem;

			@media screen and (max-width: breakpoint(sm)) {
				font-size: 1.1rem;
				line-height: 1.3rem;
			}
		}

		.bar {
			position: relative;
			background-color: $c-white;
			border-radius: 0.5rem;
			height: 0.5rem;
			width: 100%;

			.filler {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				border-radius: 0.5rem;
				background-color: $c-blue-cloud;
			}
		}
	}
}

.board-container {
	position: relative;
	display: grid;
	justify-items: center;
	align-items: center;
	padding: 3.5rem 0;

	@media screen and (max-width: breakpoint(sm)) {
		align-content: start;
		padding: 2.1rem 0 5.5rem;
	}

	.btn-previous {
		position: absolute;
		z-index: 20;
		right: 0;
		bottom: 0;
	}
}

.slide-wrapper {
	position: relative;
	z-index: 10;
	display: grid;
	grid-template-rows: 1fr 1.3rem;

	@media screen and (max-width: breakpoint(sm)) {
		display: none;
	}

	&::before {
		content: "";
		position: absolute;
		z-index: 20;
		top: 0;
		left: 0;
		height: 100%;
		width: 100%;
		border-radius: 1.3rem;
		background: linear-gradient(197.25deg, $c-grey-dark 0%, #22254b 100%);
	}

	&::after {
		position: relative;
		z-index: 30;
		content: "";
		border-bottom-left-radius: 1.3rem;
		border-bottom-right-radius: 1.3rem;
		background-color: $c-blue-cloud;
	}

	.polygon-blue {
		position: absolute;
		top: 4rem;
		left: -1.4rem;
		display: grid;
		height: 4rem;
		width: 4rem;
		transform: rotateZ(-45deg);

		.polygon-container {
			position: relative;

			&::before,
			&::after {
				content: "";
				position: absolute;
				z-index: 20;
				background-color: $c-white;
				border-bottom-right-radius: 1.3rem;
				width: 4rem;
				height: 4rem;
				top: 0;
				right: 0.4rem;
				transform: translateY(calc((100% - 0.3rem) * -1));
			}

			&::after {
				top: -0.4rem;
				right: auto;
				left: 0;
				transform: translateX(calc((100% - 0.3rem) * -1));
			}

			.shape {
				position: absolute;
				z-index: 10;
				top: 0.3rem;
				left: 0.3rem;
				width: 2rem;
				height: 2rem;
				background: linear-gradient(197.25deg, $c-grey-dark 0%, #22254b 100%);
				border-radius: 100%;

				&::before,
				&::after {
					content: "";
					position: absolute;
					top: 1rem;
					left: -0.3rem;
					height: 4rem;
					width: 4rem;
					background: linear-gradient(197.25deg, $c-grey-dark 0%, #22254b 100%);
				}

				&::after {
					top: -0.3rem;
					left: 1rem;
				}
			}
		}
	}

	.polygon-white {
		position: absolute;
		z-index: 40;
		top: 5.3rem;
		left: 0;
		width: 1.5rem;
		height: 1.5rem;
		transform-origin: center;
		transform: rotateZ(-45deg);

		&::before {
			position: absolute;
			content: "";
			height: 50%;
			width: 100%;
			top: 0;
			left: 0;
			background-color: $c-white;
			border-radius: 0.5rem;
		}

		&::after {
			position: absolute;
			content: "";
			height: 100%;
			width: 50%;
			top: 0;
			left: 0;
			background-color: $c-white;
			border-radius: 0.5rem;
		}

		.shape {
			position: absolute;
			top: 50%;
			left: 50%;
			height: 1.7rem;
			width: 0.75rem;
			background-color: #ffffff;
			transform-origin: center;
			transform: translate(calc(-50% + 0.04rem), calc(-50% + 0.04rem)) rotateZ(45deg);
			border-radius: 0.5rem;
		}
	}

	.shield-group {
		position: absolute;
		z-index: 10;
		top: 21.3rem;
		right: 1.2rem;
		width: auto;
		height: 13rem;

		&:not(.shield-group--front) {
			right: -3.5rem;

			@media screen and (max-width: breakpoint(lg)) {
				display: none;
			}
		}

		&--front {
			z-index: 30;

			@media screen and (max-width: breakpoint(sm)) {
				display: none;
			}
		}

		&--bottom {
			top: auto;
			bottom: -1.7rem;
			left: -5.6rem;
		}

		@media screen and (max-width: breakpoint(lg)) {
			top: 20.1rem;
			right: 1.6rem;
		}

		@media screen and (max-width: breakpoint(md)) {
			top: 16.6rem;
		}
	}

	&:not(.context-solutions) {
		.slide-container {
			.content {
				grid-template-rows: 1fr;
			}
		}
	}
}

.slide-container {
	position: relative;
	z-index: 40;
	display: grid;
	grid-template-columns: minmax(0, 1fr);
	grid-template-rows: auto 1fr;
	row-gap: 2rem;
	padding: 3.5rem 5rem 3.5rem 4.5rem;

	@media screen and (max-width: breakpoint(md)) {
		padding: 2.4rem 2.7rem 2.4rem 3.2rem;
	}

	.title {
		margin: 0;
		font-size: 3.9rem;
		font-weight: $fw-bold;
		line-height: 5.4rem;

		@media screen and (max-width: breakpoint(lg)) {
			font-size: 3.5rem;
			line-height: 4.5rem;
		}

		@media screen and (max-height: 920px) {
			font-size: 3.5rem;
			line-height: 4.5rem;
		}

		@media screen and (max-height: 768px) {
			font-size: 2.8rem;
			line-height: 3.5rem;
		}
	}

	.content,
	.gallery {
		position: relative;
		z-index: 50;
		align-content: end;
	}

	.gallery {
		position: relative;
		display: grid;
		align-content: center;
		grid-template-areas:
			". aphp aphp aphp aphp aphp . . ."
			". aphp aphp aphp aphp aphp . bouygues bouygues"
			". aphp aphp aphp aphp aphp . bouygues bouygues"
			". . . . . . . bouygues bouygues"
			"sncf sncf . . . . . . ."
			"sncf sncf . altran altran altran altran altran ."
			"sncf sncf . altran altran altran altran altran ."
			". . . altran altran altran altran altran ."
			". . . . . . . . ."
			". ey ey . . . . bnp bnp"
			". ey ey rte rte rte . bnp bnp"
			". . . rte rte rte . bnp bnp"
			". . . rte rte rte . . .";
		grid-template-columns: repeat(9, calc(100% / 9));
		grid-auto-rows: calc(8.6rem / 3);

		@media screen and (max-width: breakpoint(lg)) {
			grid-auto-rows: calc(7.2rem / 3);
		}

		@media screen and (max-width: breakpoint(md)) {
			grid-auto-rows: calc(5.8rem / 3);
		}

		@media screen and (max-height: 768px) {
			grid-template-rows: repeat(13, calc(100% / 13));
			grid-auto-rows: auto;
			height: min(100%, 37.3rem);
		}

		&::before {
			content: "";
			position: absolute;
			top: 0;
			left: -4.5rem;
			width: calc(100% + 9.5rem);
			height: calc(100% + 3.5rem);
			background: linear-gradient(
				90deg,
				rgba($c-grey-dark, 0) 0%,
				hsla(237, 40%, 18%, 0.2) 52.69%,
				rgba($c-grey-dark, 0.7) 100%
			);

			@media screen and (max-width: breakpoint(md)) {
				left: -3.2rem;
				width: calc(100% + 5.9rem);
				height: calc(100% + 2.4rem);
			}
		}

		.img-container {
			img {
				display: block;
				width: 100%;
				height: 100%;
				object-fit: contain;

				@media screen and (max-height: 768px) {
					width: auto;
					height: 6.4rem;
					margin: 0 auto;
				}
			}
		}

		// image gallery
		.img-altran {
			grid-area: altran;
		}

		.img-aphp {
			grid-area: aphp;
		}

		.img-bnp {
			grid-area: bnp;
		}

		.img-bouygues {
			grid-area: bouygues;
		}

		.img-ey {
			grid-area: ey;
		}

		.img-rte {
			grid-area: rte;
		}

		.img-sncf {
			grid-area: sncf;
		}
	}

	.slider {
		width: calc(100% + 9.5rem);
		margin-left: -4.5rem;

		@media screen and (max-width: breakpoint(md)) {
			width: calc(100% + 5.9rem);
			margin-left: -3.2rem;
		}
	}
}

/* ---------------------------------- POPIN --------------------------------- */
.popin {
	position: fixed;
	z-index: 100;
	bottom: 10.3rem;
	left: 3.5rem;
	display: grid;
	width: min(40.1rem, calc(100% - 4rem));
	grid-template-columns: 3rem auto;
	align-items: center;
	justify-content: start;
	column-gap: 2rem;
	padding: 2rem 2.7rem 2rem 2rem;
	background-color: $c-white;
	border-radius: 0.7rem;
	animation: 6s forwards fade-in-out;

	@media screen and (max-width: breakpoint(sm)) {
		display: none;
	}

	.animHelperFrame {
		svg {
			g {
				&:first-child {
					path {
						fill: rgba($c-status-warning, 0.2);
					}
				}

				&:not(:first-child) {
					path {
						fill: $c-status-warning;
					}
				}
			}
		}
	}

	.popin-content {
		color: $c-grey-wet;
		font-size: 1.5rem;
		font-weight: $fw-semibold;
		line-height: 2rem;
	}
}

/* --------------------------------- SLIDER --------------------------------- */
.slider {
	display: grid;
	grid-template-columns: minmax(0, 1fr);
	justify-items: center;
	row-gap: 4.5rem;
	overflow: hidden;

	@media screen and (max-width: breakpoint(xl)) {
		row-gap: 3rem;
	}

	.slides-wrapper {
		width: min(34.5rem, calc(100% - 9.5rem));

		@media screen and (max-width: breakpoint(md)) {
			width: calc(100% - 5.9rem);
		}

		.slides {
			position: relative;
			display: grid;
			grid-auto-flow: column;
			grid-auto-columns: 100%;
			align-items: center;
			column-gap: 3.9rem;
			min-height: 44.6rem;
			transition-duration: 0.5s;

			@media screen and (max-width: breakpoint(lg)) {
				column-gap: 2.4rem;
			}

			@media screen and (max-width: breakpoint(md)) {
				column-gap: 1.6rem;
			}

			@media screen and (max-height: 768px) {
				min-height: 38.6rem;
			}
		}
	}

	.slide {
		position: relative;
		display: grid;
		grid-template-rows: 1fr auto;
		row-gap: 6.6rem;
		padding: 2.3rem 2.3rem 3.6rem 3.2rem;
		border-radius: 0.5rem;
		background-color: $c-blue-cloud;
		height: calc(100% - 4.9rem);
		overflow: hidden;
		transition-duration: 0.3s;

		@media screen and (max-height: 768px) {
			row-gap: 1.6rem;
		}

		&.is-current {
			height: 100%;
			transition-delay: 0.1s;

			&::before {
				opacity: 0;
			}
		}

		&::before {
			content: "";
			position: absolute;
			opacity: 1;
			z-index: 30;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
			background-color: $c-blue-cloud;
			border-radius: 0.5rem;
			transition-duration: 0.3s;
		}

		.btn {
			position: absolute;
			z-index: 50;
			top: 0;
			left: 0;
			height: 100%;
			width: 100%;
		}

		.text {
			color: initial;
			font-size: 2.1rem;
			font-weight: $fw-semibold;
			line-height: 3.5rem;

			@media screen and (max-width: breakpoint(md)) {
				font-size: 1.7rem;
				line-height: 2.5rem;
			}
		}

		.author {
			display: grid;
			grid-template-columns: 3.7rem 1fr;
			column-gap: 1.6rem;
			align-items: start;

			.picture {
				height: 4rem;
			}

			.author-title {
				font-size: 1.5rem;
				font-weight: $fw-semibold;
				line-height: 2.1rem;
			}
		}
	}

	.navigation {
		display: grid;
		grid-auto-flow: column;
		grid-auto-columns: 0.9rem;
		grid-auto-rows: 0.9rem;
		column-gap: 1.1rem;
		justify-content: center;

		.bullet {
			border-radius: 100%;
			background-color: $c-grey-rose;
			transition-duration: 0.3s;

			&.is-current {
				background-color: $c-white;
			}
		}
	}
}

/* ---------------------------------- PAGES --------------------------------- */
/* ------------------------- INFORMATION & SOLUTIONS ------------------------ */
:is(.context-information, .context-solutions).board-wrapper {
	.content {
		display: grid;
		grid-template-columns: 4rem 1fr;
		grid-template-areas:
			"icon title"
			". content";
		gap: 3.1rem 2.4rem;
		width: min(68rem, 100%);
		align-items: center;

		@media screen and (max-width: breakpoint(md)) {
			grid-template-areas:
				"title title"
				"content content";
		}

		> .img-container {
			display: grid;
			align-items: center;
			justify-items: center;
			grid-area: icon;
			height: 4rem;
			border-radius: 0.4rem;
			background-color: $c-blue-river;

			@media screen and (max-width: breakpoint(md)) {
				display: none;
			}

			svg {
				width: 2.7rem;
				height: auto;
			}
		}

		> .title {
			grid-area: title;
			font-size: 2.1rem;
			font-weight: $fw-semibold;
			line-height: 3.1rem;

			@media screen and (max-width: breakpoint(sm)) {
				font-size: 1.6rem;
				line-height: 2.2rem;
			}
		}
	}
}

/* ------------------------------- INFORMATION ------------------------------ */
.board-wrapper.context-information {
	.board-container {
		@media screen and (max-width: breakpoint(sm)) {
			align-content: stretch;
		}
	}

	.form {
		@media screen and (max-width: breakpoint(sm)) {
			display: grid;
			grid-template-rows: 1fr auto;
			height: 100%;
			padding-bottom: 1.6rem;
		}

		.form-input-wrapper {
			@media screen and (max-width: breakpoint(sm)) {
				align-content: center;
			}
		}

		.btn {
			margin-top: 20.8rem;

			@media screen and (min-width: breakpoint(sm) + 1) {
				width: min(31.1rem, 100%);
			}

			@media screen and (max-width: breakpoint(sm)) {
				margin-top: 2.8rem;
			}

			@media screen and (max-height: 768px) {
				margin-top: 6.8rem;
			}
		}
	}

	.content {
		.choices {
			display: grid;
			grid-template-columns: minmax(0, 1fr);
			row-gap: 2.4rem;
			grid-area: content;

			@media screen and (max-height: 768px) {
				row-gap: 1.6rem;
			}

			.item {
				position: relative;
				padding: 1.9rem 6.4rem 1.9rem 3rem;
				font-size: 1.8rem;
				line-height: 2.2rem;
				background-color: $c-white;
				border-radius: 0.7rem;
				box-shadow: 0 0.1rem 0.5rem rgba($c-black, 0.03);
				transition: border-color 0.3s;

				@media screen and (max-height: 768px) {
					padding: 1.6rem 4.4rem 1.6rem 2rem;
					font-size: 1.6rem;
					line-height: 1.8rem;
				}

				&:hover {
					&::after {
						opacity: 1;
						right: 2rem;
					}

					.btn {
						border-color: $c-blue-cloud;
					}
				}

				&::after {
					content: "";
					opacity: 0;
					position: absolute;
					top: 50%;
					right: 3.2rem;
					width: 2.4rem;
					height: 2rem;
					transform: translateY(-50%);
					background-image: url("../assets/icons/icon-arrow.svg");
					background-repeat: no-repeat;
					background-size: contain;
					background-position: center;
					transition-duration: 0.3s;
				}

				.btn {
					position: absolute;
					z-index: 20;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					border: solid 0.2rem $c-white;
					border-radius: 0.7rem;
					transition-duration: 0.3s;
				}
			}
		}
	}
}

.slide-wrapper.context-information {
	.shield-group.shield-group--front {
		z-index: 50;
	}

	.content {
		display: grid;
		grid-template-columns: minmax(0, 1fr);
		align-items: end;

		@media screen and (max-height: 768px) {
			align-items: center;
			align-content: center;
		}
	}
}

/* -------------------------------- SOLUTIONS ------------------------------- */
.board-wrapper.context-solutions {
	.board-container {
		@media screen and (max-width: breakpoint(sm)) {
			grid-template-rows: auto 1fr auto;
			align-items: stretch;
		}

		.content {
			grid-template-areas:
				"icon title"
				". content"
				". btn";
			width: min(73rem, 100%);

			@media screen and (max-width: breakpoint(md)) {
				grid-template-areas:
					"title title"
					"content content"
					"btn btn";
				align-items: stretch;
				grid-template-rows: auto 1fr auto;
				margin-top: 6.2rem;
			}

			.options {
				display: grid;
				grid-template-columns: repeat(auto-fit, minmax(20rem, 1fr));
				gap: 1.8rem;
				grid-area: content;

				@media screen and (max-width: breakpoint(sm)) {
					grid-template-columns: 1fr;
					align-self: start;
				}

				.item {
					position: relative;
					display: grid;
					row-gap: 3rem;
					padding: 2.2rem 2rem 1.4rem;
					border: solid 0.2rem $c-white;
					border-radius: 0.3rem;
					background-color: $c-white;
					box-shadow: 0 0.2rem 1.5rem rgba($c-black, 0.05);
					transition-duration: 0.3s;

					@media screen and (min-width: breakpoint(sm) + 1) {
						grid-template-rows: repeat(2, auto);
					}

					@media screen and (max-width: breakpoint(sm)) {
						grid-template-columns: auto 1fr;
						column-gap: 1.5rem;
						padding: 1.5rem 1.7rem;
					}

					@media screen and (max-height: 768px) {
						row-gap: 1.8rem;
					}

					&:hover {
						border-color: $c-blue-cloud;
					}

					&.is-selected {
						border-color: $c-blue-ocean;
						background-color: $c-blue-ocean;

						:is(&.item--lp, &.item--lt, &.item--lf) .img-container {
							background-color: $c-blue-cloud;

							svg path {
								fill: $c-white;
							}
						}

						.item-content {
							.title {
								color: $c-white;
							}

							.text {
								color: $c-grey-mat;
							}
						}
					}

					.btn {
						position: absolute;
						top: -0.2rem;
						left: -0.2rem;
						width: calc(100% + 0.4rem);
						height: calc(100% + 0.4rem);
					}

					&--lp {
						.img-container {
							background-color: #f9e39c;
						}
					}

					&--lt {
						.img-container {
							background-color: #cbd3f9;
						}
					}

					&--lf {
						.img-container {
							background-color: #b7e5c4;
						}
					}

					.img-container {
						display: grid;
						align-items: center;
						justify-items: center;
						width: 3.7rem;
						height: 3.7rem;
						border-radius: 0.3rem;

						@media screen and (max-width: breakpoint(sm)) {
							width: 3.3rem;
							height: 3.3rem;
						}

						svg {
							height: 2rem;

							@media screen and (max-width: breakpoint(sm)) {
								height: 1.5rem;
							}

							path {
								fill: $c-grey-dark;
							}
						}
					}

					.item-content {
						font-weight: $fw-semibold;

						.title {
							color: $c-grey-wet;
							font-size: 1.5rem;
							line-height: 1.8rem;
							margin-bottom: 0.3rem;

							@media screen and (max-width: breakpoint(sm)) {
								font-size: 1.3rem;
								line-height: 1.5rem;
							}
						}

						.text {
							font-size: 1.8rem;
							line-height: 2.2rem;

							@media screen and (max-width: breakpoint(sm)) {
								font-size: 1.5rem;
								line-height: 2.2rem;
							}
						}
					}
				}
			}

			> .btn {
				grid-area: btn;

				@media screen and (min-height: 769px) {
					margin-top: 13.1rem;
				}

				@media screen and (min-width: breakpoint(sm) + 1) {
					justify-self: start;
					width: min(42.9rem, 100%);
				}

				@media screen and (max-width: breakpoint(sm)) {
					margin-top: 3rem;
				}
			}
		}
	}
}

.slide-wrapper.context-solutions {
	.title {
		@media screen and (min-height: 921px) {
			font-size: 3.5rem;
			line-height: 4.7rem;
		}
	}

	.content {
		display: grid;
		grid-template-columns: minmax(0, 1fr);
		grid-auto-rows: 3.4rem;
		row-gap: 10rem;
		justify-self: center;
		width: min(37.4rem, 100%);

		@media screen and (max-width: breakpoint(lg)) {
			row-gap: 6rem;
		}

		@media screen and (max-height: 768px) {
			row-gap: 4rem;
		}

		.img-container {
			&:nth-child(even) {
				img {
					margin-left: auto;
				}
			}

			img {
				display: block;
				width: auto;
				height: 100%;
			}
		}
	}
}

/* ------------------------------ APPOINTEMENT ------------------------------ */
.board-wrapper.context-appointement {
	.board-container {
		@media screen and (max-width: breakpoint(sm)) {
			align-content: start;
		}
	}

	.content {
		display: grid;
		grid-template-columns: minmax(0, 1fr);
		width: min(59.9rem, 100%);
		overflow: hidden;

		@media screen and (max-width: breakpoint(sm)) {
			margin-top: 3.5rem;
		}

		svg {
			width: 21.5rem;
			height: 10rem;

			@media screen and (max-width: breakpoint(xs)) {
				height: 6.7rem;
				width: 14.5rem;
			}
		}

		.title {
			margin-top: 3.1rem;
			font-size: 2.5rem;
			font-weight: $fw-semibold;
			line-height: 3.5rem;

			@media screen and (max-width: breakpoint(xs)) {
				margin-top: 2rem;
				font-size: 1.9rem;
				line-height: 2.7rem;
			}
		}

		.text {
			margin-top: 2.5rem;
			color: $c-grey-wet;
			font-size: 1.7rem;
			font-weight: $fw-semibold;
			line-height: 3rem;

			@media screen and (max-width: breakpoint(xs)) {
				margin-top: 2rem;
				font-size: 1.5rem;
				line-height: 2.4rem;
			}

			ul {
				font-weight: $fw-medium;
				margin: 0;
				padding-inline-start: 3rem;
			}
		}

		.content-footer {
			display: grid;
			row-gap: 1.7rem;
			margin-top: 8.1rem;

			@media screen and (max-width: breakpoint(sm)) {
				margin-top: 5.4rem;
			}

			@media screen and (max-height: 768px) {
				margin-top: 1rem;
			}
		}

		.buttons-container {
			display: flex;
			flex-wrap: wrap;
			justify-content: center;
			width: calc(100% + 1.4rem);
			margin-left: -0.7rem;

			@media screen and (max-width: breakpoint(md)) {
				flex-direction: column;
			}

			&.is-single {
				width: 100%;
				margin: 0;

				.btn {
					margin: 0;
				}
			}

			.btn {
				flex-grow: 1;
				font-size: 1.7rem;
				line-height: 2.1rem;
				margin: 1.4rem 0.7rem 0;

				&.is-disabled {
					@media screen and (min-width: breakpoint(md) + 1) {
						max-width: 27.4rem;
					}

					@media screen and (max-width: breakpoint(xs)) {
						font-size: 1.6rem;
						line-height: 2rem;
					}
				}
			}
		}

		.detail {
			color: $c-grey-medium;
			font-size: 1.3rem;
			font-weight: $fw-semibold;
			line-height: 2.1rem;

			@media screen and (max-width: breakpoint(xs)) {
				font-size: 1.1rem;
				line-height: 1.6rem;
			}
		}
	}
}

.slide-wrapper.context-appointement {
	.content {
		display: grid;
		align-items: end;

		.paragraphs {
			display: grid;
			row-gap: 6rem;
			margin-bottom: 7.8rem;

			@media screen and (max-width: breakpoint(lg)) {
				row-gap: 3.2rem;
				margin-bottom: 4.5rem;
			}

			.item {
				display: grid;
				grid-template-columns: minmax(0, 1fr);
				justify-items: start;
				row-gap: 0.5rem;
				font-size: 2.4rem;
				font-weight: $fw-semibold;
				line-height: 3rem;
				color: $c-blue-cloud;

				@media screen and (max-width: breakpoint(lg)) {
					font-size: 1.8rem;
					line-height: 2.4rem;
				}

				.number {
					color: $c-white;
				}

				.number {
					font-size: 4.1rem;
					line-height: 4.5rem;

					@media screen and (max-width: breakpoint(lg)) {
						font-size: 3.2rem;
						line-height: 3.8rem;
					}

					@media screen and (max-width: breakpoint(lg)) {
						font-size: 2.4rem;
						line-height: 2.8rem;
					}
				}
			}
		}

		.small-text {
			color: $c-white;
			font-size: 1.8rem;
			font-weight: $fw-semibold;
			line-height: 3rem;
		}
	}
}

/* -------------------------------- CALENDAR -------------------------------- */
.board-wrapper.context-calendar {
	.board-container {
		@media screen and (max-width: breakpoint(sm)) {
			align-content: stretch;
			padding: 4.1rem 0 5.9rem;
		}
	}

	.calendar-container {
		position: relative;
		height: min(67.2rem, 100%);
		width: min(105rem, 100%);

		#loader-1 {
			position: absolute;
			z-index: 20;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}

		iframe {
			position: absolute;
			z-index: 40;
			top: 0;
			left: 0;
			border-radius: 1rem;
		}
	}
}

.slide-wrapper.context-calendar {
	&::after {
		content: none;
	}

	.slide-container {
		border-radius: 1.3rem;

		.title {
			position: absolute;
			bottom: 3.9rem;
			left: 50%;
			writing-mode: vertical-rl;
			text-orientation: mixed;
			transform: translateX(-50%) rotateZ(180deg);
		}
	}

	.shield-group.shield-group--front {
		top: 4.1rem;
	}
}

/* ------------------------------ SUBSCRIPTION ------------------------------ */
.board-wrapper.context-subscription {
	&.context-personal {
		.content-container {
			row-gap: 5.6rem;

			@media screen and (max-width: breakpoint(sm)) {
				margin-top: 7.3rem;
			}

			@media screen and (max-height: 768px) {
				row-gap: 3.6rem;
				margin-top: 0;
			}

			.content-header {
				@media screen and (max-width: breakpoint(sm)) {
					display: none;
				}
			}
		}

		.form {
			@media screen and (max-width: breakpoint(sm)) {
				display: grid;
				grid-template-rows: repeat(2, auto) 1fr;
			}
		}

		.form-tel-container {
			margin-bottom: 3.6rem;

			@media screen and (max-width: breakpoint(sm)) {
				margin-bottom: 2.8rem;
			}

			@media screen and (max-height: 768px) {
				margin-bottom: 2rem;
			}
		}

		.form-input-tel-wrapper {
			.special-label {
				position: relative;
				top: auto;
				left: auto;
				padding: 0;
				background: transparent;
				font-family: $montserrat;
			}

			.form-control {
				width: 100%;
				padding-left: 7.2rem;

				&:hover:not(:focus) {
					border-color: $c-grey-mat;
				}

				@media screen and (max-width: breakpoint(sm)) {
					padding-left: 6rem;
				}
			}

			.flag-dropdown {
				top: auto;
				height: 6.5rem;

				@media screen and (max-width: breakpoint(sm)) {
					height: 5.2rem;
				}

				.selected-flag {
					padding-left: 2.1rem;
				}
			}

			.arrow {
				opacity: 0;
			}
		}

		.form-footer {
			margin-top: 10rem;

			@media screen and (max-width: breakpoint(sm)) {
				margin-top: 2.8rem;
				align-content: end;
			}

			@media screen and (max-height: 920px) {
				margin-top: 7rem;
			}

			@media screen and (max-height: 768px) {
				margin-top: 2rem;
				align-content: end;
			}
		}
	}

	&:not(.context-personal) {
		.board-container {
			@media screen and (max-width: breakpoint(sm)) {
				padding-bottom: 7.4rem;
			}

			.content-container {
				@media screen and (max-width: breakpoint(sm)) {
					margin-top: 6.2rem;
					grid-template-rows: auto 1fr;
				}

				.form {
					@media screen and (max-width: breakpoint(sm)) {
						display: grid;
						align-content: space-between;
					}
				}

				.form-footer {
					margin-top: 12.8rem;

					@media screen and (max-width: breakpoint(sm)) {
						margin-top: 2.8rem;
					}
				}
			}
		}
	}

	.board-container {
		@media screen and (max-width: breakpoint(sm)) {
			align-content: stretch;
			align-items: stretch;
			grid-template-rows: auto 1fr;
		}
	}

	.content-container {
		display: grid;
		row-gap: 2.8rem;
		width: min(59.9rem, 100%);
		margin: 0 auto;

		@media screen and (max-width: breakpoint(sm)) {
			row-gap: 3.7rem;
		}

		.content-header {
			display: grid;
			row-gap: 0.9rem;
			justify-items: start;
			font-weight: $fw-semibold;

			.title {
				font-size: 2.7rem;
				line-height: 3.3rem;

				@media screen and (max-width: breakpoint(sm)) {
					font-size: 2.1rem;
					line-height: 2.6rem;
				}
			}

			.text {
				color: $c-grey-wet;
				font-size: 1.7rem;
				line-height: 2.7rem;

				@media screen and (max-width: breakpoint(sm)) {
					font-size: 1.5rem;
					line-height: 2.2rem;
				}
			}
		}

		.form-footer {
			display: grid;
			row-gap: 2.4rem;

			@media screen and (max-width: breakpoint(sm)) {
				row-gap: 3.5rem;
			}

			.btn {
				width: min(37.3rem, 100%);

				@media screen and (max-width: breakpoint(sm)) {
					font-size: 1.8rem;
					line-height: 2.2rem;
				}
			}
		}
	}
}

.slide-wrapper.context-subscription {
	&.context-personal {
		.slide-container {
			@media screen and (max-height: 768px) {
				align-content: stretch;
				grid-template-rows: auto 1fr;
			}

			.content {
				@media screen and (max-height: 768px) {
					grid-template-rows: 1fr;
					align-content: center;
					align-items: center;
				}
			}
		}
	}

	.slide-container {
		align-content: space-between;
		grid-template-rows: repeat(2, auto);
	}

	.content {
		display: grid;
		row-gap: 2rem;
		justify-items: center;

		.badges-gallery {
			display: grid;
			grid-template-columns: repeat(3, 1fr);
			grid-auto-rows: min(6.8rem, 25%);
			grid-template-areas:
				"first . ."
				"first second ."
				". second third"
				". . third";
			column-gap: 2.2rem;

			.img-container {
				@media screen and (max-height: 920px) {
					height: 12rem;
				}

				&:first-child {
					grid-area: first;
				}

				&:nth-child(2) {
					grid-area: second;
				}

				&:last-child {
					grid-area: third;
				}

				img {
					width: 100%;
					height: 100%;
					object-fit: contain;
				}
			}
		}

		> img {
			width: min(19.1rem, 100%);
		}

		.big-text {
			font-size: 1.9rem;
			font-weight: $fw-bold;
			line-height: 2.9rem;

			@media screen and (max-height: 768px) {
				font-size: 1.6rem;
				line-height: 2.6rem;
			}
		}
	}
}

/* ----------------------------- SUCCESS MESSAGE ---------------------------- */
.board-wrapper.context-success-message {
	justify-items: center;
	align-items: start;
	row-gap: 7.2rem;

	@media screen and (max-width: breakpoint(sm)) {
		row-gap: 5rem;
	}

	.board-container {
		position: relative;
		z-index: 20;
		align-self: stretch;
		padding-top: 0;
		width: min(58rem, 100%);
		max-height: 68rem;

		@media screen and (max-width: breakpoint(sm)) {
			max-height: 55rem;
			align-content: stretch;
			padding: 0;
		}

		.content {
			display: flex;
			flex-direction: column;
			align-self: stretch;
			position: relative;
			z-index: 20;
			padding: 4.7rem 5rem 4rem;
			background-color: $c-white;
			box-shadow: 0 0.1rem 0.5rem rgba($c-black, 0.03);
			border-radius: 0.7rem;

			@media screen and (max-width: breakpoint(sm)) {
				padding: 2.6rem 3rem 2.3rem;
			}

			.emoji {
				font-size: 3.7rem;
				font-weight: $fw-bold;
				line-height: 6rem;
				margin-bottom: 1.5rem;
			}

			.title {
				margin-bottom: 0.5rem;
				font-size: 4.1rem;
				font-weight: $fw-bold;
				line-height: 5.1rem;

				@media screen and (max-width: breakpoint(sm)) {
					font-size: 3.1rem;
					line-height: 4.1rem;
				}

				span {
					color: $c-blue-river;
				}
			}

			.subtitle {
				color: $c-grey-wet;
				font-size: 1.9rem;
				font-weight: $fw-semibold;
				line-height: 2.7rem;

				@media screen and (max-width: breakpoint(sm)) {
					font-size: 1.5rem;
					line-height: 2.3rem;
				}
			}

			.text {
				margin-top: 7.1rem;
				color: $c-grey-medium;
				font-size: 1.5rem;
				font-weight: $fw-medium;
				line-height: 2rem;
			}

			.hint {
				flex-grow: 1;
				display: grid;
				grid-template-columns: 2.2rem auto;
				align-items: center;
				align-content: end;
				column-gap: 0.9rem;
				margin-top: 7.1rem;

				.hint-icon {
					display: grid;
					align-items: center;
					justify-items: center;
					height: 2.2rem;
					border-radius: 100%;
					background-color: #afffd7;

					svg {
						width: 1rem;
						height: auto;
					}
				}

				.hint-text {
					color: $c-grey-wet;
					font-size: 1.5rem;
					font-weight: $fw-semibold;
					line-height: 2.1rem;

					@media screen and (max-width: breakpoint(sm)) {
						font-size: 1.3rem;
						line-height: 1.8rem;
					}
				}
			}
		}

		.shields-img-container {
			right: -3.3rem;
			bottom: 2rem;
		}
	}

	.shields-img-container,
	& + .shields-img-container {
		position: absolute;
		z-index: 10;

		@media screen and (max-width: breakpoint(md)) {
			display: none;
		}

		img {
			width: 12.8rem;
			height: auto;
		}
	}

	& + .shields-img-container {
		top: 26.3rem;
		right: 2.5rem;
	}
}

/* ------------------------------- ANIMATIONS ------------------------------- */
@keyframes fade-in-out {
	0% {
		opacity: 0;
		visibility: hidden;
		transform: translateY(3.5rem);
	}
	8% {
		opacity: 1;
		visibility: visible;
		transform: translateY(0);
	}
	92% {
		opacity: 1;
		visibility: visible;
		transform: translateY(0);
	}
	100% {
		opacity: 0;
		visibility: hidden;
		transform: translateY(3.5rem);
	}
}
